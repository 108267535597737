img.logo {
  width: 2.5rem;
  vertical-align: middle;
}

.material-icons {
  vertical-align: bottom;
  font-size: 18px !important;
}



.card {
  min-height: 300px !important;
}

nav.rdt_TableFooter {
  line-height: unset;
  box-shadow: unset;
}

/* Animation (Add After) */
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-out;
}

div.autocomplete-dropdown-container {
  border-bottom: 1px solid rgb(185, 185, 185);
  border-left: 1px solid rgb(185, 185, 185);
  border-right: 1px solid rgb(185, 185, 185);
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -2px;

  z-index: 9999;
  background-color: white;
}
.suggestion-item {
  cursor: pointer;
  padding: 10px;
}
.suggestion-item--active {
  background-color: rgb(229, 250, 217);
  cursor: pointer;
  padding: 10px;
}

.modal-form {
  width: 90% !important;
  height: 100% !important;
}

.container-layout {
  margin-left: 200px;
}
@media only screen and (max-width: 600px) {
  .main-sider {
    position: absolute;
    height: 100vh;
    z-index: 100000;
  }
  .container-layout {
    margin-left: 0px;
  }
}
.pac-container {
  z-index: 9999;
}

.react-daterange-picker__range-divider {
  padding-right: 10px;
  padding-top: 4px;
}

.react-date-picker__calendar--open {
z-index: 9999;
}

.react-date-picker__wrapper {
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  height: 40px;
  margin-top:-2px;
}


.view-label{
  font-weight: bold;
}

.ReactTable .rt-tr-group {
    cursor: pointer;
    
}

fieldset {
  border: 10px;
}

main {
  padding: 20px,
  
}